.exercise-image {
  width: 100px;
}

.exercise-video {
  width: 100px;
}

.exercise {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.top-bar {
  top: 0px;
  position: sticky;
}

.exercise-new {
  background-color: darkslategrey;
  padding-bottom: 10px;
}

.pad {
  height: 20px;
  background-color: darkgoldenrod;
}

.exercise-wrapper {
  display: flex;
}

.exercise-image-file-input {
  width: 100px;
}

.exercise-video-file-input {
  width: 100px;
}

.exercise-image-wrapper {
  width: 2%;
}

.exercise-video-wrapper {
  width: 2%;
}

.exercise-wrapper-buttons {
  display: flex;
  width: 5%;
  flex-direction: column;
}

.exercise-title {
  width: 6%;
}

.exercise-difficulty {
  width: 5%;
}

.exercise-type {
  width: 5%;
}

.exercise-description {
  width: 30%;
  padding: 10px;
}

.App {
  text-align: center;
  background-color: darkgrey;
}

.filter {
  display: flex;
  background-color: darkslategrey;
  align-items: flex-start;
  justify-content: space-evenly;
  justify-items: flex-start;
  flex-direction: row;
  padding-bottom: 5px;
}
